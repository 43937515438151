import { useState, useEffect, forwardRef } from 'react';
import './Consult.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const Consult = forwardRef((props, ref) => {
  const baseUrl = "https://api.telerad.ps/api";
  // const baseUrl = "https://mostaql-1.test/api";//
  const [step, setStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [file, setFile] = useState(null);
  const [subFile, setSubFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileLink, setFileLink] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [medicalCentreCustomerType, setMedicalCentreCustomerType] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [referenceCode, setReferenceCode] = useState("");
  const [referenceCodeExpaird, setReferenceCodeExpaird] = useState(false);
  const [referenceCodeExpairdError, setReferenceCodeExpairdError] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [email, setEmail] = useState(null);
  const [amount, setAmount] = useState(null);

  const [services, setServices] = useState([]);
  const [plans, setPlans] = useState([]);

  const [verified, setVerified] = useState(false);
  const [verifiedError, setVerifiedError] = useState(false);
  const [token, setToken] = useState(null);

  const handleCaptchaChange = (captchaToken) => {
    if (captchaToken) {
      console.log("Captcha verified:", captchaToken);
      setVerified(true);
      setVerifiedError(false)
      setToken(captchaToken);
    } else {
      setVerified(false);
      setToken(null);
    }
  };
  
  const [medicalCentreInfo, setMedicalCentreInfo] = useState({
    name: "",
    email: "",
    phone: "",
    contactPerson: ""
  });
  const [individualInfo, setIndividualInfo] = useState({
    fullName: "",
    email: "",
    birthDate: "",
    phone: "",
    subFile: "",
    gender: null
  });

  const [loading, setLoading] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(null);
  

  

  const checkIndividualInfo = () => {
    return (
      individualInfo.fullName === "" || individualInfo.email === "" ||
      individualInfo.birthDate === "" || individualInfo.phone === "" ||
      individualInfo.subFile === "" || individualInfo.gender === null
    )
  }
  const checkMedicalCentreInfo = () => {
    return (
      medicalCentreInfo.name === "" || medicalCentreInfo.email === "" ||
      medicalCentreInfo.contactPerson === "" || medicalCentreInfo.phone === ""
    )
  }


    // Function to update medicalCentreInfo state
    const handleMedicalCentreChange = (e) => {
      const { name, value } = e.target;
      if (name === "email") {setEmail(value)}
      setMedicalCentreInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value
      }));
    };
  
    // Function to update individualInfo state

    const handleIndividualChangeFile = (event) => {
      const uploadedFile = event.target.files[0];
  
      if (uploadedFile) {
        const formData = new FormData();
        formData.append('file', uploadedFile);
  
        // Use Axios to upload the file
        axios.post(baseUrl + '/upload-file-to-timp', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        })
          .then((response) => {
            if (response.data.status === 200) {
              console.log('File uploaded successfully:', response.data);
              setIndividualInfo((prevInfo) => ({
                ...prevInfo,
                subFile: response.data.fileUrl
              }));
            } else {
              console.error('Error uploading file:', response.data.message);
            }
          })
          .catch((error) => {
            console.error('Error uploading file:', error);
          });
      }
    };

    const handleIndividualChange = (e) => {
      const { name, value } = e.target;
      if (name === "email") {setEmail(value)}
      setIndividualInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value
      }));
    };
    useEffect(() => {console.log(token)},[token])
    const handlePayments = async () => {
      setLoading(true);
      if (!verified) {
        setVerifiedError(true)
        setLoading(false);
        return;
      }
      setVerifiedError(false)
      try {
        const response = await axios.post(baseUrl +"/verify-recaptcha", {
          token:token,
        });
        console.log("Backend Response:", response.data);
  
        if (response.data.success) {
          console.log(response.data.message);
          runPayment()
        } else {
          setVerifiedError(true)
          setLoading(false);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setLoading(false);
      }
    };

    const runPayment = async () => {
      try {
        // Proceed to initialize payment with the tokenized card
        const res = await axios.post(baseUrl + "/payment/initialize", {
          amount: amount,
          email: email,
          currency: "ILS"
        });
        if(res.data.status === true){
          setPaymentResponse((prev)=>prev=res.data);
        }
      } catch (error) {
        console.error("Payment initialization failed:", error);
      } finally {
        setLoading(false);
      }
    }
    
    useEffect(() => {
      console.log(paymentResponse)
      if(paymentResponse?.data){
        individualStore().then((data)=>{
          console.log(data);
          if(data.status === 201){
            window.location = paymentResponse.data.authorization_url;
          }
        })
      }
    },[paymentResponse])

  const [timerActive, setTimerActive] = useState(false);

  const [confirmationCountdown, setConfirmationCountdown] = useState(5); // for step 10


  const getOptionData = async (forSection) => {
    let url = (forSection == 'medical-centre') ? "get-medical-centre-service" : "get-individual-service";
    const res = await axios.get(`${baseUrl}/${url}`);
    if (res.status === 200) {
      if(forSection == 'medical-centre') setPlans(res.data.data)
      else setServices(res.data.data);
      return;
    }
    console.log("Error In Get Option Data", res.data)
  }


  useEffect(() => {
    getOptionData('medical-centre')
    getOptionData('individual')
  },[])




  const medicalCentreStore = async (storeFor) => {
    let medicalCentreStoreData;
    let url = (storeFor == "new") ? "store-medical-centre" : "store-medical-centre-service"
    if (storeFor === 'existing') {
      medicalCentreStoreData = {
        selectedService:selectedService.service_name,
        reference_code:referenceCode,
        file:fileUrl,
        fileLink:fileLink,
        fullName: individualInfo.fullName,
        referenceCode:referenceCode
      };
    }
    if (storeFor === 'new') {
      if(referenceCodeExpaird) {
        url = "update-medical-centre"
        medicalCentreStoreData = {
          // plan:selectedPlan.id, 
          plan:plans[plans.length - 1].id,
          reference_code:referenceCode,
        };
      }else{
        medicalCentreStoreData = {
          plan:plans[plans.length - 1].id,
          ...medicalCentreInfo
        };
      }
    }
    


    try {
      const res = await axios.post(`${baseUrl}/${url}`,medicalCentreStoreData,{
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // setUploadProgress(percentCompleted); // Update progress
            console.log(`progress: ${percentCompleted}%`);
          },
        });
      if (res.data.status === 201) {
        console.log('Medical Centre data saved successfully!');
      }else {
        console.log('Error Medical Centre Store: ',res.data)
      }
    } catch (error) {
      console.error('Error Medical Centre Store:', error);
    }
  }



  const individualStore = async () => {
    let individualStoreData;

    individualStoreData = {
      selectedService:selectedService.service_name,
      file:fileUrl,
      fileLink:fileLink,
      fullName: individualInfo.fullName,
      email: individualInfo.email,
      subFile: individualInfo.subFile,
      birthDate: individualInfo.birthDate,
      phone: individualInfo.phone,
      gender: individualInfo.gender,
      reference:paymentResponse.data.reference
    }
    try {
      const res = await axios.post(`${baseUrl}/store-individual`,individualStoreData,{
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          // setUploadProgress(percentCompleted); // Update progress
          console.log(`progress: ${percentCompleted}%`);
        },
      });
      if (res.data.status === 201) {
        console.log('Individual data saved successfully!');
        return res.data
      }
    } catch (error) {
      console.error('Error individual Store:', error);
      return {
        status: 0,
        message: error
      }
    }
  }

  useEffect(() => {
    if (selectedOption === 'medical-centre' && step === 11 && medicalCentreCustomerType === 'new') {
      setTimerActive(true);
      setConfirmationCountdown(5); // Reset countdown
    } else {
      setTimerActive(false);
    }
    if (selectedOption === 'medical-centre' && step === 13 && medicalCentreCustomerType === 'existing') {
      setTimerActive(true);
      setConfirmationCountdown(5); // Reset countdown
    } else {
      setTimerActive(false);
    }
  }, [selectedOption, step, medicalCentreCustomerType]);

  useEffect(() => {
    if (timerActive) {
      const interval = setInterval(() => {
        setConfirmationCountdown(prev => {
          if (prev <= 1) {
            clearInterval(interval);
            if(step === 13 && medicalCentreCustomerType === 'existing'){
              setStep(9);
            }else{
              setMedicalCentreCustomerType('existing');
              setStep(8);
            }
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timerActive]);


  const nextStep = async () => {
    if (step === 1 && selectedOption === 'individual') {
      setStep(2);
    } else if (step === 1 && selectedOption === 'medical-centre') {
      setStep(7); // Move to step 7 for Medical Centre
    } else if (step === 2 && selectedOption === 'individual') {
      setStep(3);
    } else if (step === 3 && selectedOption === 'individual' && selectedService !== null) {
      setStep(4);
    } else if (step === 4 && selectedOption === 'individual' && (fileUrl || fileLink)) {
      setStep(5);
    } else if (step === 5 && selectedOption === 'individual') {
      setStep(6);
    } else if (step === 6 && selectedOption === 'individual') {
      // individualStore()
      setStep(7);
    } else if (step === 7 && selectedOption === 'medical-centre' && medicalCentreCustomerType !== null) {
      console.log("toto");
      // to get referenceCode
      setStep(8); // Proceed to the next step after selecting customer type for Medical Centre
    } else if (step === 8 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new') {
      console.log("toto2");
      // setStep(9); // Move to step 9 for new Medical Centre
      setStep(11); // Move to step 9 for new Medical Centre
      medicalCentreStore('new')
    } else if (step === 8 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing') {
      const res = await axios.post(`${baseUrl}/check-reference-code`,{
        reference_code:referenceCode,
      });
      console.log("check-reference-code",res.data);
      if (res.status === 200 && res.data.message == 'yes') {
        setStep(9); // Move to step 9 for existing Medical Centre
      }else{
        setReferenceCodeExpairdError(true)
        // setReferenceCodeExpaird(true)
        // setMedicalCentreCustomerType('new')
        // setStep(9);
        // setStep(1);
      }
    } else if (step === 9 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new') {
      // console.log("end - payment"); // payment
      setStep(10); // Move to step 10 for new Medical Centre
    } else if (step === 9 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing' && selectedService !== null) {
      console.log("toto");
      setStep(10); // Only move to step 10 if a service is selected for existing Medical Centre
    } else if (step === 10 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new') {
      medicalCentreStore('new')
      setStep(11); // Move to step 11 for new Medical Centre
    }
      else if (step === 10 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing') {
      medicalCentreStore('existing')
      setFile(null);
      setFileName("");
      setFileLink("");
      setFileUrl(null);
      setSelectedService(null);
      setStep(13);
    } 
    else if (step === 11 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new') {
        setStep(12);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      if (selectedOption === 'medical-centre') {
        if (step > 7) {
          setStep(step - 1);
        } else if (step === 7) {
          setStep(1); // Go back to the initial step when in Medical Centre and at step 7
          setSelectedOption(null);
        }
      } else {
        setStep(step - 1);
      }
    }
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    // Reset steps and selected values if option changes
    setStep(1);
    setSelectedService(null);
    setFile(null);
    setFileName('');
    setFileLink('');
    setUploadProgress(0);
    setMedicalCentreCustomerType(null);
    setConfirmationCountdown(10);
  };

  const selectService = (service) => {
    setSelectedService(service);
    setAmount(service.price * 100);
  };

  // const handleFileUpload = (event) => {
  //   const uploadedFile = event.target.files[0];
  //   if (uploadedFile) {
  //     setFile(uploadedFile);
  //     setFileName(uploadedFile.name);
  //     // Simulate upload progress
  //     let progress = 0;
  //     const interval = setInterval(() => {
  //       if (progress < 100) {
  //         progress += 10;
  //         setUploadProgress(progress);
  //       } else {
  //         clearInterval(interval);
  //       }
  //     }, 100);
  //   }
  // };


  const handleSubFileUpload = (event) => {
    const uploadedFile = event.target.files[0];

    if (uploadedFile) {
      const formData = new FormData();
      formData.append('file', uploadedFile);

      // Use Axios to upload the file
      axios.post(baseUrl + '/upload-file-to-timp', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      })
        .then((response) => {
          if (response.data.status === 200) {
            console.log('File uploaded successfully:', response.data);
            setFileUrl(response.data.fileUrl); // Save the Timp file URL
          } else {
            console.error('Error uploading file:', response.data.message);
          }
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
        });
    }
  };


  
  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];

    if (uploadedFile) {
      const formData = new FormData();
      formData.append('file', uploadedFile);

      // Use Axios to upload the file
      axios.post(baseUrl + '/upload-file-to-timp', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      })
        .then((response) => {
          if (response.data.status === 200) {
            console.log('File uploaded successfully:', response.data);
            setFileUrl(response.data.fileUrl); // Save the Timp file URL
          } else {
            console.error('Error uploading file:', response.data.message);
          }
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
        });
    }
  };

  const handleLinkChange = (event) => {
    setFileLink(event.target.value);
  };

  const handlePlanSelection = (plan) => {
    setSelectedPlan(plan);
    setAmount(plan.price * 100)
  };
  

  const isNextButtonDisabled = () => {
    switch (step) {
      case 1:
        return selectedOption === null;
      case 2:
        return selectedOption === 'individual' ? checkIndividualInfo() : medicalCentreCustomerType === null;
      case 3:
        return selectedOption === 'individual' && selectedService === null;
      case 4:
        return selectedOption === 'individual' && !(fileUrl || fileLink);
      case 10:
        return selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing' && !(fileUrl || fileLink);
      case 5:
        return selectedOption === 'individual';
      case 8:
        return (selectedOption === 'medical-centre' && medicalCentreCustomerType === null ) || (checkMedicalCentreInfo() && medicalCentreCustomerType === 'new' ) || (!referenceCode && medicalCentreCustomerType === 'existing' );
      case 9:
        if (selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new') {
          return selectedPlan === null;
        }
        if (selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing') {
          return selectedService === null;
        }
        return false;
      default:
        return false;
    }
  };

  const { t } = useTranslation();

  return (
    <div ref={ref} className="consult-container">
<div className="consult-left">
      <h1 className="consult-title" style={{fontSize: '2rem', lineHeight: '1.75'}} dangerouslySetInnerHTML={{ __html: t('consult.title') }}></h1>
      <p className="consult-subtitle">{t('consult.subtitle')}</p>

      <div className="consult-step-counter">
        {step === 1 && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step1.brief')}</p>
            <span className="step-number">{t('consult.steps.step1.number')}</span>
          </div>
        )}

        {step === 2 && selectedOption === 'individual' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step2_individual.brief')}</p>
            <span className="step-number">{t('consult.steps.step2_individual.number')}</span>
          </div>
        )}

        {step === 3 && selectedOption === 'individual' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step3_individual.brief')}</p>
            <span className="step-number">{t('consult.steps.step3_individual.number')}</span>
          </div>
        )}

        {step === 4 && selectedOption === 'individual' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step4_individual.brief')}</p>
            <span className="step-number">{t('consult.steps.step4_individual.number')}</span>
          </div>
        )}

        {step === 5 && selectedOption === 'individual' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step5_individual.brief')}</p>
            <span className="step-number">{t('consult.steps.step5_individual.number')}</span>
          </div>
        )}

        {step === 6 && selectedOption === 'individual' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step6_individual.brief')}</p>
            <span className="step-number">{t('consult.steps.step6_individual.number')}</span>
          </div>
        )}

        {step === 7 && selectedOption === 'medical-centre' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step2_medical_centre.brief')}</p>
            <span className="step-number">{t('consult.steps.step2_medical_centre.number')}</span>
          </div>
        )}

        {step === 8 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step3_medical_centre_new.brief')}</p>
            <span className="step-number">{t('consult.steps.step3_medical_centre_new.number')}</span>
          </div>
        )}

        {step === 8 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step3_medical_centre_existing.brief')}</p>
            <span className="step-number">{t('consult.steps.step3_medical_centre_existing.number')}</span>
          </div>
        )}

        {step === 9 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step4_medical_centre_new.brief')}</p>
            <span className="step-number">{t('consult.steps.step4_medical_centre_new.number')}</span>
          </div>
        )}

        {step === 9 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step4_medical_centre_existing.brief')}</p>
            <span className="step-number">{t('consult.steps.step4_medical_centre_existing.number')}</span>
          </div>
        )}

        {step === 10 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step5_medical_centre_new.brief')}</p>
            <span className="step-number">{t('consult.steps.step5_medical_centre_new.number')}</span>
          </div>
        )}

        {step === 10 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step6_medical_centre_existing.brief')}</p>
            <span className="step-number">{t('consult.steps.step6_medical_centre_existing.number')}</span>
          </div>
        )}

        {step === 11 && selectedOption === 'medical-centre' && (
          <div className="step-box">
            <p className="step-brief">{t('consult.steps.step7_medical_centre_existing.brief')}</p>
            <span className="step-number">{t('consult.steps.step7_medical_centre_existing.number')}</span>
          </div>
        )}
      </div>
    </div>
      <div className="consult-right">
      {step === 1 && (
  <div className="consult-step consult-step1">
    <button
      className="consult-option"
      onClick={() => {
        selectOption('individual'); // Select "Individual" option
        setStep(2); // Move directly to the next step
      }}
    >
      <img src="https://icons.veryicon.com/png/o/miscellaneous/linear-icon-6/icon_-individual.png" alt="Individual" />
      <p>{t('consult.option.individual.title')}</p>
    </button>
    <button
      className="consult-option"
      onClick={() => {
        selectOption('medical-centre'); // Select "Medical Centre" option
        setStep(7); // Move directly to the next step
      }}
    >
      <img src="https://cdn-icons-png.flaticon.com/512/5572/5572281.png" alt="Medical Centre" />
      <p>{t('consult.option.MedicalCentre.title')}</p>
    </button>
  </div>
)}


        {selectedOption === 'individual' && step === 2 && (
          <div className="consult-step consult-step2">
            <input type="text" 
              name='fullName'
              value={individualInfo.fullName}
              onChange={handleIndividualChange}
              placeholder={t('consult.option.individual.fullname')} />
            <input type="date" 
              name="birthDate"
              value={individualInfo.birthDate}
              onChange={handleIndividualChange}
              placeholder={t('consult.option.individual.dob')} />
            <input type="text" 
              name='phone'
              value={individualInfo.phone}
              onChange={handleIndividualChange}
              placeholder={t('consult.option.individual.mobile')} />
            <input type="email" 
              name='email'
              value={individualInfo.email}
              onChange={handleIndividualChange}
              placeholder={t('consult.option.individual.email')} />
            <label htmlFor="subfile">التاريخ الطبي</label>
              <input
              id='subfile'
                type="file"
                accept="image/*,application/pdf"
                onChange={handleIndividualChangeFile}
                className="file-input"
              />
            <select 
              name='gender'
              value={individualInfo.gender}
              onChange={handleIndividualChange}
            >
              <option value={null}>{t('consult.option.individual.gender')}</option>
              <option value={0}>{t('consult.option.individual.male')}</option>
              <option value={1}>{t('consult.option.individual.female')}</option>
            </select>
          </div>
        )}

        {selectedOption === 'individual' && step === 3 && (
          <div className="consult-step consult-step3">
            <p>{t('consult.option.individual.individual-service')}</p>
            {services.map((service, index) => (
              <button key={index} onClick={() => selectService(service)}>
                {t(`consult.option.individual.${service.service_code}`)} ({service.price} ILS)
              </button>
            ))}
          </div>
        )}

        {selectedOption === 'individual' && step === 4 && (
          <div className="consult-step consult-step4">
            <div className="upload-section">
              <h3>{t('consult.option.individual.upload')}</h3>
              <i className="upload-icon">☁️</i>
              <input
                type="file"
                accept="image/*,application/pdf"
                onChange={handleFileUpload}
                className="file-input"
              />
              <input
                type="text"
                placeholder={t('consult.option.individual.link')}
                value={fileLink}
                onChange={handleLinkChange}
                className="link-input"
              />
              {file && (
                <div className="file-info">
                  <p>{t('consult.option.individual.uploaded')} {fileName}</p>
                  <progress value={uploadProgress} max="100"></progress>
                </div>
              )}
              {fileLink && (
                <div className="file-info">
                  <p>{t('consult.option.individual.provided')}<a href={fileLink} target="_blank" rel="noopener noreferrer">{fileLink}</a></p>
                </div>
              )}
            </div>
          </div>
        )}

        {selectedOption === 'individual' && step === 5 && (
          <div className="consult-step consult-step5">
            <h2 className="consult-plan-title">{t('consult.option.individual.plantitle')}</h2>
            <p>{t('consult.option.individual.plantitlep1')}<span className="highlight">{t(`consult.option.individual.${selectedService.service_code}`)}</span></p>
            <p>{t('consult.option.individual.plantitlep2')}</p>
            <div className="consult-summary">
              <p>{t('consult.option.individual.amount.0')}<span>{amount/100} ILS</span></p>
            </div>
            <button className="proceed-button" onClick={nextStep}>{t('consult.option.individual.proceed')}</button>
          </div>
        )}

        {step === 6 && selectedOption === 'individual' && (
          <div className="consult-step consult-step6">
            <div className="payment-container">
              <div className="payment-option">
                <label htmlFor="debit-credit">
                {t('consult.option.individual.card')}
                  <div className="card-icons">
                    <img src="https://cdn-icons-png.flaticon.com/512/196/196578.png" alt="Visa" />/
                    <img src="https://cdn-icons-png.flaticon.com/512/196/196561.png" alt="MasterCard" />
                  </div>
                </label>
              </div>
              <div className="card-section">
              <div className="captcha">

              <ReCAPTCHA
          sitekey="6LdrJ6MqAAAAAM2t-wn7fVv3bEdeXGnqjP8wSz5j" // Replace with your site key
          size="normal"
          onChange={handleCaptchaChange}
        />
        </div>
        {verifiedError && <p className='ReCAPTCHA-error'>{t('consult.option.individual.captcha_error')}</p>}
              <button onClick={handlePayments} className="submit-button" 
              disabled={loading}
              >
                {loading ? "..." : t('consult.option.individual.pay')}
              </button>
              <p >
              {t('consult.option.individual.pay_text')}
              </p>
              </div> 

            </div>
          </div>
        )}

        {selectedOption === 'medical-centre' && step === 7 && (
          <div className="consult-step consult-step7">
            <button
              className={`consult-option ${medicalCentreCustomerType === 'new' ? 'selected' : ''}`}
              onClick={() => setMedicalCentreCustomerType('new')}
            >
              {t('consult.option.MedicalCentre.newCustomer')}
            </button>
            <button
              className={`consult-option ${medicalCentreCustomerType === 'existing' ? 'selected' : ''}`}
              onClick={() => setMedicalCentreCustomerType('existing')}
            >
              {t('consult.option.MedicalCentre.existingCustomer')}
            </button>
          </div>
        )}

        {selectedOption === 'medical-centre' && step === 8 && medicalCentreCustomerType === 'new' && (
          <div className="consult-step consult-step8">
            <input type="text" 
            name='name'
            value={medicalCentreInfo.name}
            onChange={handleMedicalCentreChange}
            placeholder={t('consult.option.MedicalCentre.name')} />
            <input type="text" 
            name='contactPerson'
            value={medicalCentreInfo.contactPerson}
            onChange={handleMedicalCentreChange}
            placeholder={t('consult.option.MedicalCentre.contactPerson')} />
            <input type="text" 
            name='phone'
            value={medicalCentreInfo.phone}
            onChange={handleMedicalCentreChange}
            placeholder={t('consult.option.MedicalCentre.mobile')} />
            <input type="email" 
            name='email'
            value={medicalCentreInfo.email}
            onChange={handleMedicalCentreChange}
            placeholder={t('consult.option.MedicalCentre.email')} />
          </div>
        )}

{selectedOption === 'medical-centre' && step === 8 && medicalCentreCustomerType === 'existing' && (
          <div className="consult-step consult-step8">
            <p>{t('consult.option.MedicalCentre.RefCode')}</p>
            <input type="text" placeholder={t('consult.option.MedicalCentre.Code')} onChange={(e)=>{
              setReferenceCodeExpairdError(false)
              setReferenceCode(e.target.value)
            }} style={{
                  outline: referenceCodeExpairdError &&  "#ff000087",
                  backgroundColor: referenceCodeExpairdError &&  "#ff000021",
                  border: referenceCodeExpairdError && "1px solid #ff000087",
            }}/>

          </div>
        )}

{selectedOption === 'medical-centre' && step === 9 && medicalCentreCustomerType === 'new' && (
  <div className="consult-step consult-step9">
    {plans?.map((plan,index)=> (
      <button
        className={`consult-option ${selectedPlan === plan ? 'selected' : ''}`}
        onClick={() => handlePlanSelection(plan)}
      >
        {t(`consult.option.MedicalCentre.plans.${plan.plan_code}.name`)}
        <p className='plan-brief'>{t(`consult.option.MedicalCentre.plans.${plan.plan_code}.price`,{
          price:plan.price
        })}</p>
        <p className='plan-price'>{t(`consult.option.MedicalCentre.plans.${plan.plan_code}.dis`)}</p>
      </button>
    ))}
  </div>
)}


{selectedOption === 'medical-centre' && step === 9 && medicalCentreCustomerType === 'existing' && (
  <div className="consult-step consult-step9-existing">
    <p>{t('consult.option.MedicalCentre.select')}</p>
    {services.map((service, index) => (
              <button key={index} onClick={() => selectService(service)}>
                {t(`consult.option.individual.${service.service_code}`)}
              </button>
    ))}
  </div>
)}


{selectedOption === 'medical-centre' && step === 10 && medicalCentreCustomerType === 'new' && (
  <div className="consult-step consult-step10-payment">
  <div className="payment-container">
    <div className="payment-option">
      <label htmlFor="debit-credit">
      {t('consult.option.individual.card')}
      <div className="card-icons">
                    <img src="https://cdn-icons-png.flaticon.com/512/196/196578.png" alt="Visa" />/
                    <img src="https://cdn-icons-png.flaticon.com/512/196/196561.png" alt="MasterCard" />
                  </div>
      </label>
    </div>
    <div className="card-section">

              {/* <button onClick={handlePayments} className="submit-button" disabled={loading}>
                {loading ? "..." : t('consult.option.individual.pay')}
              </button>
              <p >
              {t('consult.option.individual.pay_text')}
              </p> */}
              </div> 
  </div>
</div>
)}


{selectedOption === 'medical-centre' && step === 11 && medicalCentreCustomerType === 'new' && (
        <div className="consult-step consult-step11">
          <p>{t('consult.option.MedicalCentre.confirm')}</p>
          <p>{t('consult.option.MedicalCentre.confirmp1')}</p>
          <p>{t('consult.option.MedicalCentre.confirmp2')} {confirmationCountdown}</p>
        </div>
      )}

{selectedOption === 'medical-centre' && step === 13 && medicalCentreCustomerType === 'existing' && (
        <div className="consult-step consult-step11">
          <p>{t('consult.option.MedicalCentre.confirm')}</p>
          <p>{t('consult.option.MedicalCentre.confirmp1')}</p>
          <p>{confirmationCountdown}</p>
        </div>
      )}


{selectedOption === 'medical-centre' && step === 10 && medicalCentreCustomerType === 'existing' && (
  <div className="consult-step consult-step10">
    {t('consult.option.individual.upload')}
    <input type="file" accept="image/*,application/pdf" onChange={handleFileUpload} />
    <input type="text" placeholder={t('consult.option.individual.fileLink')} value={fileLink} onChange={handleLinkChange} />
    {file && <p className="file-info">{t('consult.option.individual.uploaded')} {fileName}</p>}
    {fileLink && <p className="file-info">{t('consult.option.individual.provided')} {fileLink}</p>}
  </div>
)}





{!(step === 1 ) &&
  !(selectedOption === 'medical-centre' && step === 10 && medicalCentreCustomerType === 'new') && (
    <div className="consult-navigation">
      <button className="nav-button back-button" onClick={prevStep}>
        {t('consult.Back')}
      </button>
      <button
        className="nav-button next-button"
        onClick={nextStep}
        disabled={isNextButtonDisabled()}
      >
        {t('consult.Next')}
      </button>
    </div>
  )}

      </div>
    </div>
  );
});

export default Consult;
