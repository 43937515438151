import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faInstagram, faLinkedinIn, faCcVisa, faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import logo from './telerad-logo.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';

const Footer = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';

    const currentYear = new Date().getFullYear();

    const scrollToComponent = (componentId) => {
        scroller.scrollTo(componentId, {
            duration: 100,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    };

    return (
        <footer className={`footer-container ${isRTL ? 'rtl' : ''}`}>
            <div className="footer-content">
                <div className="footer-left">
                    <img src={logo} alt={t('footer.title.part1')} className="footer-logo-large" />
                    <h2 className="footer-title-large">
                        <span>{t('footer.title.part1')}</span>
                        <span>{t('footer.title.part2')}</span>
                        <span>{t('footer.title.part3')}</span>
                        <span>{t('footer.title.part4')}</span>
                    </h2>
                    <div className="footer-contact">
                        <p><FontAwesomeIcon icon={faPhone} className="footer-icon" /> {t('footer.contact.phone')}</p>
                        <p><FontAwesomeIcon icon={faEnvelope} className="footer-icon" /> {t('footer.contact.email')}</p>
                        <p><FontAwesomeIcon icon={faMapMarkerAlt} className="footer-icon" /> {t('footer.contact.address')}</p>
                        <p><FontAwesomeIcon icon={faMapMarkerAlt} className="footer-icon" /> {t('contact.address2')}</p>
                    </div>
                    <div className="footer-social">
                        <FontAwesomeIcon icon={faFacebookF} />
                        <FontAwesomeIcon icon={faXTwitter} />
                        <FontAwesomeIcon icon={faInstagram} />
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </div>
                </div>
                <div className="footer-right">
                    <div className="footer-menu" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ul className="footer-list">
                            <li>
                                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>{t('footer.menu.home')}</Link>
                            </li>
                            <li>
                                <Link to="/services" style={{ textDecoration: 'none', color: 'inherit' }}>{t('footer.menu.ourVision')}</Link>
                            </li>
                            <li>
                                <button
                                    className="footer-link"
                                    onClick={() => scrollToComponent('consult')}
                                >
                                    {t('footer.menu.ourMission')}
                                </button>
                            </li>
                            <li>
                                <button
                                    className="footer-link"
                                    onClick={() => scrollToComponent('team')}
                                >
                                    {t('footer.menu.ourTeam')}
                                </button>
                            </li>
                            <li>
                                <button
                                    className="footer-link"
                                    onClick={() => scrollToComponent('contact')}
                                >
                                    {t('footer.menu.contact')}
                                </button>
                            </li>
                        </ul>
                        <div className="payment-methods">
                            <h4>{t('footer.payment_methods.head')}</h4>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faCcVisa} />
                                    <span className="sr-only">Visa</span>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCcMastercard} />
                                    <span className="sr-only">Mastercard</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>{t('footer.footerText', { year: currentYear })}</p>
                <div className="footer-links">
                    <Link to="/privacy-policy" style={{ textDecoration: 'none', color: 'inherit', marginRight: '15px' }}>
                        {t('footer.privacyPolicy')}
                    </Link>
                    <span className="separator1">•</span>
                    <Link to="/refund-policy" style={{ textDecoration: 'none', color: 'inherit' }}>
                        {t('footer.refundPolicy')}
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
